import {createStore, applyMiddleware, compose} from 'redux';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import {persistStore} from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

import createReducer from './reducer';
import sagas from './sagas';

const isProduction = process.env.NODE_ENV !== 'development'
const _history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middleware = [
  applyMiddleware(routerMiddleware(_history)),
  applyMiddleware(sagaMiddleware),
];

if (!isProduction) {
  middleware.push(applyMiddleware(logger));
}

const composeEnhancers = !isProduction && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const _store = createStore(createReducer(_history), {}, composeEnhancers(...middleware));

sagaMiddleware.run(sagas);

export const store = _store;
export const history = _history;
export const persistor = persistStore(_store);
