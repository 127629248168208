import {createReducer} from '../utils';
import update from 'immutability-helper';
import moment from 'moment';
import {
  SET_SPRINTS,
  SET_WORKOUTS,
  SET_DEV_WORKOUTS,
  REMOVE_USER_DEV_WORKOUT,
  REMOVE_USER_DEV_WORKOUTS,
  REPLACE_USER_DEV_WORKOUTS,
  ADD_USER_DEV_WORKOUTS,
  SET_COACH_SPRINT,
  SET_COACH_SPRINT_ANALYTICS,
  SET_COACH_SPRINTS_ANALYTICS,
  UPDATE_SPRINTS,
  SET_FEEDBACK,
  UPDATE_FEEDBACK,
  SET_EMAIL_TEMPLATE,
  LOADING_SPRINTS,
  NEW_MAPS_REQUEST,
  NEW_MAPS_SUCCESS,
  MAP_UPDATE_REQUEST_SUCCESS,
  SWITCH_CUSTOMERS,
  REMOVE_USER_WORKOUT,
  REMOVE_USER_WORKOUTS,
  ADD_USER_WORKOUTS,
  SET_ADMINS,
  REMOVE_ADMIN,
  ADD_ADMIN,
  REPLACE_USER_WORKOUTS,
  SET_NFFS,
  ADD_USER_NFFS,
  REPLACE_USER_NFFS,
  REMOVE_USER_NFFS,
  REMOVE_USER_NFF,
  MERGE_CUSTOMERS,
  SET_MANAGERS,
  REMOVE_MANAGER,
  SET_UPDATED_MANAGER,
  ADD_MANAGER,
  SET_ANALYTICS_STATISTICS,
  SET_SPRINTS_COMPANIES,
  SET_ALL_DEV_WORKOUTS,
} from '../actions/types';

const initialState = {
  sprints: [],
  workouts: [],
  devWorkouts: [],
  analytics: null,
  feedback: [],
  coachSprint: null,
  emailTemplate: '',
  loading: false,
  requests: [],
  admins: [],
  managers: [],
  companies: [],
  nffs: [],
};

const adminReducer = createReducer(initialState, {
  [LOADING_SPRINTS]: (state, {loading}) => {
    return {
      ...state,
      loading,
    };
  },
  [SET_WORKOUTS]: (state, {workouts}) => {
    return {
      ...state,
      workouts: [...workouts],
    };
  },
  [SET_ALL_DEV_WORKOUTS]: (state, {devWorkouts}) => {
    return {
      ...state,
      devWorkouts: [...devWorkouts],
    };
  },
  [SET_DEV_WORKOUTS]: (state, {devWorkouts}) => {
    return {
      ...state,
      devWorkouts: [...devWorkouts],
    };
  },
  [SET_NFFS]: (state, {nffs}) => {
    return {
      ...state,
      nffs: [...nffs],
    };
  },
  [SET_ADMINS]: (state, {admins}) => {
    return {
      ...state,
      admins: [...admins],
    };
  },
  [REMOVE_ADMIN]: (state, {id}) => {
    return {
      ...state,
      admins: [...state.admins.filter(admin => admin.id !== id)],
    };
  },
  [REMOVE_MANAGER]: (state, {id}) => {
    return {
      ...state,
      managers: [...state.managers.filter(manager => manager._id !== id)]
    };
  },
  [ADD_ADMIN]: (state, {admin}) => {
    return {
      ...state,
      admins: [...state.admins, admin],
    };
  },
  [SET_SPRINTS]: (state, {sprints, total}) => {
    return {
      ...state,
      sprints: [...sprints],
      total,
    };
  },
  [SET_SPRINTS_COMPANIES]: (state, {companies}) => {
    return {
      ...state,
      companies: [...companies],
    };
  },
  [SET_COACH_SPRINT]: (state, {sprint}) => {
    return {
      ...state,
      coachSprint: sprint,
    };
  },
  [SET_COACH_SPRINT_ANALYTICS]: (state, {data}) => {
    return {
      ...state,
      analytics: data
    };
  },
  [SET_COACH_SPRINTS_ANALYTICS]: (state, {data}) => {
    return {
      ...state,
      analytics: data,
    };
  },
  [UPDATE_SPRINTS]: (state, {params: {sprintId, fieldToUpdate, value}}) => {
    const {sprints} = state;
    const sprintToUpdate = sprints.find((sprint) => sprint._id === sprintId);

    sprintToUpdate[fieldToUpdate] = value;

    return {
      ...state,
      sprints: [...sprints],
    };
  },
  [SWITCH_CUSTOMERS]: (state, {params: {updatedNewSprint, updatedOldSprint}}) => {
    const sprints = state.sprints.map((sprint) => {
      if (sprint._id === updatedNewSprint._id) {
        return updatedNewSprint;
      }

      if (sprint._id === updatedOldSprint._id) {
        return updatedOldSprint;
      }

      return sprint;
    });

    return {
      ...state,
      sprints: [...sprints]
    };
  },
  [MERGE_CUSTOMERS]: (state, {params: {updateCoachSprints}}) => {
    const sprints = state.sprints.map((sprint) => {
      const updated =  updateCoachSprints.find(({_id}) => _id === sprint._id);

      if (updated) {
        return updated;
      }

      return sprint;
    });

    return {
      ...state,
      sprints: [...sprints]
    };
  },
  [SET_FEEDBACK]: (state, {feedback}) => {
    const sortedFeedback = feedback.sort(sortFeedback);

    return {
      ...state,
      feedback: [...sortedFeedback],
    };
  },
  [UPDATE_FEEDBACK]: (state, {feedbackId, fieldToUpdate, value}) => {
    const {feedback} = state;
    const feedbackToUpdate = feedback.find(
      (feedback) => feedback._id === feedbackId,
    );

    feedbackToUpdate[fieldToUpdate] = value;

    return {
      ...state,
      feedback: [...feedback],
    };
  },
  [SET_EMAIL_TEMPLATE]: (state, {emailTemplate}) => (
    {
      ...state,
      emailTemplate,
    }
  ),
  [NEW_MAPS_REQUEST]: state => ({...state, loading: true}),
  [NEW_MAPS_SUCCESS]: (state, {requests}) => {
    return {
      ...state,
      requests,
      loading: false
    }
  },
  [MAP_UPDATE_REQUEST_SUCCESS]: (state, {userId, sprintId}) => {
    const {requests} = state;
    const userRowIndex = requests.findIndex(r => r.id === userId);

    if (userRowIndex < 0) {
      return state;
    }

    const userSprints = requests[userRowIndex].coachSprints.filter(cs => cs._id !== sprintId);

    if (!userSprints.length) {
      return update(state, {
        requests: {$splice: [[userRowIndex, 1]]}
      });
    }

    return update(state, {
      requests: {
        [userRowIndex]: {
          coachSprints: {$set: userSprints}
        }
      }
    });
  },
  [REMOVE_USER_WORKOUT]: (state, {workoutId}) => {
    return {
      ...state,
      workouts: state.workouts.filter(({_id}) => _id !== workoutId)
    };
  },
  [REMOVE_USER_DEV_WORKOUT]: (state, {workoutId}) => {
    return {
      ...state,
      devWorkouts: state.devWorkouts.filter(({_id}) => _id !== workoutId)
    };
  },
  [REMOVE_USER_DEV_WORKOUTS]: (state, {params}) => {
    const {removedWorkouts, coachSprintId} = params;

    const updatedSprints = state.sprints.map(sprint => {
      if (sprint._id === coachSprintId) {

        return {
          ...sprint,
          devWorkouts: sprint.devWorkouts.filter(({_id}) => removedWorkouts.includes(_id))
        }
      }

      return sprint;
    });

    return {
      ...state,
      sprints: [...updatedSprints]
    };
  },
  [REPLACE_USER_DEV_WORKOUTS]: (state, {params}) => {
    const {updatedWorkouts, coachSprintId} = params;
    const updatedSprints = state.sprints.map(sprint => {
      if (sprint._id === coachSprintId) {

        return {
          ...sprint,
          devWorkouts: updatedWorkouts
        }
      }

      return sprint;
    });

    return {
      ...state,
      sprints: [...updatedSprints]
    };
  },
  [ADD_USER_DEV_WORKOUTS]: (state, {params}) => {
    const {newWorkouts, coachSprintId} = params;

    const updatedSprints = state.sprints.map(sprint => {
      if (sprint._id === coachSprintId) {
        return {
          ...sprint,
          devWorkouts: [...sprint.devWorkouts, ...newWorkouts]
        }
      }
      
      return sprint;
    });
    
    return {
      ...state,
      sprints: [...updatedSprints]
    };
  },
  [REMOVE_USER_WORKOUTS]: (state, {params}) => {
    const {removedWorkouts, coachSprintId} = params;

    const updatedSprints = state.sprints.map(sprint => {
      if (sprint._id === coachSprintId) {

        return {
          ...sprint,
          workouts: sprint.workouts.filter(({_id}) => removedWorkouts.includes(_id))
        }
      }

      return sprint;
    });

    return {
      ...state,
      sprints: [...updatedSprints]
    };
  },
  [REPLACE_USER_WORKOUTS]: (state, {params}) => {
    const {updatedWorkouts, coachSprintId} = params;
    const updatedSprints = state.sprints.map(sprint => {
      if (sprint._id === coachSprintId) {

        return {
          ...sprint,
          workouts: updatedWorkouts
        }
      }

      return sprint;
    });

    return {
      ...state,
      sprints: [...updatedSprints]
    };
  },
  [ADD_USER_WORKOUTS]: (state, {params}) => {
    const {newWorkouts, coachSprintId} = params;

    const updatedSprints = state.sprints.map(sprint => {
      if (sprint._id === coachSprintId) {
        return {
          ...sprint,
          workouts: [...sprint.workouts, ...newWorkouts]
        }
      }
      
      return sprint;
    });
    
    return {
      ...state,
      sprints: [...updatedSprints]
    };
  },
  [ADD_USER_NFFS]: (state, {params}) => {
    const {nffs, coachSprintId} = params;
    
    const updatedSprints = state.sprints.map(sprint => {
      if (sprint._id === coachSprintId) {
        return {
          ...sprint,
          nffs: [...sprint.nffs, ...nffs]
        }
      }
      
      return sprint;
    });
    
    return {
      ...state,
      sprints: [...updatedSprints]
    };
  },
  [REPLACE_USER_NFFS]: (state, {params}) => {
    const {updatedNffs, coachSprintId} = params;
    const updatedSprints = state.sprints.map(sprint => {
      if (sprint._id === coachSprintId) {
        
        return {
          ...sprint,
          nffs: updatedNffs
        }
      }
      
      return sprint;
    });
    
    return {
      ...state,
      sprints: [...updatedSprints]
    };
  },
  [REMOVE_USER_NFFS]: (state, {params}) => {
    const {removedNffs, coachSprintId} = params;

    const updatedSprints = state.sprints.map(sprint => {
      if (sprint._id === coachSprintId) {

        return {
          ...sprint,
          nffs: sprint.nffs.filter(({_id}) => removedNffs.includes(_id))
        }
      }

      return sprint;
    });

    return {
      ...state,
      sprints: [...updatedSprints]
    };
  },
  [REMOVE_USER_NFF]: (state, {nffId}) => {
    return {
      ...state,
      nffs: state.nffs.filter(({_id}) => _id !== nffId)
    };
  },
  [SET_MANAGERS]: (state, {managers}) => {
    return {
      ...state,
      managers: [...managers]
    }
  },
  [SET_ANALYTICS_STATISTICS]: (state, {statistics}) => {
    return {
      ...state,
      statistics,
    }
  },
  [SET_UPDATED_MANAGER]: (state, {manager}) => {
    const {managers} = state;
    const {_id} = manager;

    const index = managers.findIndex(manager => manager._id === _id);

    managers[index] = manager;
    
    return {
      ...state,
      managers
    }
  },
  [ADD_MANAGER]: (state, {admin}) => {
    return {
      ...state,
      managers: [...state.managers, admin]
    }
  }
});

function sortFeedback(a, b) {
  const dateA = moment(a.createdAt);
  const dateB = moment(b.createdAt);

  return dateA.isBefore(dateB) ? 1 : -1;
}

export default adminReducer;
