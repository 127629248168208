import React from 'react';
import Spinner from '../Spinner';

export const PageLoader = () => {
    return (
      <div className="page-loader">
        <Spinner fontSize={96} />
      </div>
    );
  };
