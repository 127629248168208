import {
  GET_INVITATION_DATA_REQUEST,
  GET_INVITATION_DATA_SUCCESS,
  AUTH_SUCCESS,
  SIGNUP_REQUEST,
  LOGIN_REQUEST,
  ON_SIGN_OUT,
  FORGOT_PASSWORD_REQUEST,
  CLEAR_AUTH_STATUSES,
  RESET_PASSWORD_CODE_REQUEST,
  RESET_PASSWORD_CODE_SUCCESS,
  RESET_PASSWORD_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  CLEAR_AUTH_STATUSES_STAY_SIGNED,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  AGREEMENT_REQUEST,
  SET_LANGUAGE,
} from './types';

export function signIn({email, password}) {
  return {
    type: LOGIN_REQUEST,
    email,
    password,
  };
}

export function setLanguage(lng) {
  return {
    type: SET_LANGUAGE,
    lng,
  };
}

export function sendAgreement() {
  return {
    type: AGREEMENT_REQUEST
  };
}

export function getInvitationData(invitationId) {
  return {
    type: GET_INVITATION_DATA_REQUEST,
    invitationId,
  };
}

export function onInvitationDataSuccess(user) {
  return {
    type: GET_INVITATION_DATA_SUCCESS,
    user,
  };
}

export function signUp({invitationId, id, password}) {
  return {
    type: SIGNUP_REQUEST,
    invitationId,
    id,
    password,
  };
}

export function onAuthSuccess(data) {
  return {
    type: AUTH_SUCCESS,
    ...data,
  };
}

export function signOut() {
  return {
    type: ON_SIGN_OUT,
  };
}

export function forgotPasswordRequest({email, isReset}) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    email,
    isReset,
  };
}

export function clearAuthStatuses() {
  return {
    type: CLEAR_AUTH_STATUSES,
  };
}

export function clearAuthStatusesStaySigned() {
  return {
    type: CLEAR_AUTH_STATUSES_STAY_SIGNED,
  };
}

export function checkPasswordCode(code) {
  return {
    type: RESET_PASSWORD_CODE_REQUEST,
    code
  }
}

export function onPasswordCodeSuccess(user) {
  return {
    type: RESET_PASSWORD_CODE_SUCCESS,
    user
  }
}

export function resetPassword({password, passwordResetCode, id}) {
  return {
    type: RESET_PASSWORD_REQUEST,
    password,
    passwordResetCode,
    id
  }
}

export function changePassword({password, passwordResetCode, id}) {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    password,
    passwordResetCode,
    id
  }
}

export function updateProfile(id, data) {
  return {
    type: UPDATE_PROFILE_REQUEST,
    id,
    data
  }
}

export function onProfileUpdated(profile) {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    profile
  }

}
