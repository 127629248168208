import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import LazyLoader from '../../../utils/lazyLoader';
import lazyWithRetry from '../../../utils/lazyWithRetry';
import { PageLoader } from '../../PageLoader';

const Restricted = LazyLoader(
  lazyWithRetry(
    () => import(/* webpackChunkName: "restricted" */ '../../Errors/Restricted')
  ),
  <PageLoader/>
);

export default function AuthRoute({children, role, ...routeProps}) {
  const {isAuthenticated, role: userRole, profile} = useIsAuthenticated();

  function renderChildren() {
    return role.includes(userRole) ? children : <Restricted/>
  }

  function render({location}) {
    if (isAuthenticated) {
      return profile.agreementSigned
        ? renderChildren()
        : <Redirect to={{pathname: '/auth/agreement', state: {from: location}}}/>
    }

    return <Redirect to={{pathname: '/auth/login', state: {from: location}}}/>;
  }

  return (
    <Route
      {...routeProps}
      render={render}
    />
  );
}
