import {useSelector} from 'react-redux';

export default function useIsAuthenticated() {
  const {isAuthenticated, role, profile} = useSelector(({auth}) => auth);

  return {
    isAuthenticated,
    role,
    profile,
  };
}
