import {MESSAGES_CLEAR, ON_MESSAGE} from './types';

export function sendMessages(params) {
  return {
    type: ON_MESSAGE,
    ...params,
  };
}

export function clearMessages() {
  return {
    type: MESSAGES_CLEAR,
  };
}
