export const TAKING_STOCK = 'taking-stock';

const date = new Date();

export const fullYear = date.getFullYear();
export const ADMIN_ROLES = ['coach', 'manager'];
export const ADMIN_ROLE = ['coach'];

export const KEYCODES = {
  Backspace: 8,
  Tab: 9,
  Enter: 13, 
  Escape: 27,
  Slash: 191
};

export const RTL_DIRECTION_LANGUAGES = ['ar'];
