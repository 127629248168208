import {put, call, takeLatest} from 'redux-saga/effects';
import Api from '../../utils/api';
import {
  GET_USER_WORKOUTS,
  SET_USER_WORKOUTS,
  GET_USER_WORKOUT,
  REMOVE_USER_WORKOUT,
  SET_USER_WORKOUT,
  ON_MESSAGE,
  SAVE_WORKOUT_BIG_ASSUMPTION,
  SET_WORKOUT_BIG_ASSUMPTION,
  DELETE_WORKOUT_BIG_ASSUMPTION,
  REMOVE_WORKOUT_BIG_ASSUMPTION,
  SAVE_WORKOUT_EXPERIMENT,
  SET_WORKOUT_EXPERIMENT,
  UPDATE_WORKOUT_EXPERIMENT,
  UPDATE_WORKOUT_BIG_ASSUMPTION,
  TOGGLE_WORKOUT_BIG_ASSUMPTION,
  DELETE_WORKOUT_EXPERIMENT,
  SET_WORKOUT_PURPOSE_NOTES,
  SAVE_WORKOUT_NOTE,
  DELETE_WORKOUT_NOTE,
  REMOVE_WORKOUT_NOTE,
  SET_WORKOUT_CHALLENGE_NOTES,
  SAVE_WORKOUT_CHALLENGE_NOTE,
  DELETE_WORKOUT_CHALLENGE_NOTE,
  REMOVE_WORKOUT_CHALLENGE_NOTE,
  SAVE_POD_BIG_ASSUMPTION,
  DELETE_POD_BIG_ASSUMPTION,
  SAVE_POD_EXPERIMENT,
  UPDATE_POD_EXPERIMENT,
  DELETE_POD_EXPERIMENT,
  TOGGLE_POD_BIG_ASSUMPTION,
  SAVE_POD_DEVELOPMENT_NOTE,
  DELETE_POD_DEVELOPMENT_NOTE,
  SET_POD_BIG_ASSUMPTION,
  SET_POD_EXPERIMENT,
  REMOVE_POD_BIG_ASSUMPTION,
  UPDATE_POD_BIG_ASSUMPTION,
  SET_POD_DEVELOPMENT_NOTES,
  REMOVE_POD_DEVELOPMENT_NOTE,
  DELETE_USER_WORKOUT,
  DELETE_USER_WORKOUTS,
  REMOVE_USER_WORKOUTS,
  CREATE_USER_WORKOUTS,
  ADD_USER_WORKOUTS,
  SAVE_WORKOUT_QUESTION,
  SET_WORKOUT_CHALLENGE_QUESTION,
  UPDATE_USER_WORKOUTS,
  REPLACE_USER_WORKOUTS,
  TOGGLE_WORKOUT_EXPERIMENT,
  SAVE_TOGGLE_WORKOUT_EXPERIMENT,
  CREATE_WORKOUT_EXPERIMENT,
  UPDATE_WORKOUT_EXPERIMENTS,
  SET_UPDATED_WORKOUT_EXPERIMENT,
} from '../../actions/types';
import {notify} from '../../utils/airbrake';

function getWorkoutsApi() {
  return Api.get('/workout');
}

function getWorkoutApi(workoutId) {
  return Api.get(`/workout/${workoutId}`);
}

function deleteWorkoutApi(workoutId) {
  return Api.delete(`/workout/${workoutId}`);
}

function deleteWorkoutsApi(params) {
  return Api.delete('/workout/remove', params);
}

function updateWorkoutsApi(params) {
  return Api.post('/workout/update', params);
}

function createWorkoutsApi(params) {
  return Api.post('/workout', params);
}

function saveAssumptionApi(params) {
  return Api.post('/challenge/add-assumption', params);
}

function saveQuestionApi(params) {
  return Api.post(`/challenge/${params.fieldId}`, params);
}

function updateAssumptionApi({value, entityId}) {
  return Api.post(`/challenge/update-assumption/${value.bigAssumptionId}`, {value, entityId});
}

function deleteAssumptionApi({entityId, bigAssumptionId}) {
  return Api.delete(`/challenge/remove-assumption/${bigAssumptionId}`, {entityId});
}

function saveExperimentApi(params) {
  return Api.post('/experiment/entry', params);
}

function updateExperimentApi({bigAssumptionId, value}) {
  return Api.post(`/experiment/entry/${value._id}`, {
    bigAssumptionId: bigAssumptionId,
    text: value.text,
  });
}

function deleteExperimentApi({experimentId, id}) {
  return Api.delete(`/big-assumption/${id}/experiment`, {experimentId});
}

function toggleBigAssumptionApi({bigAssumptionId, isActive}) {
  return Api.post(`/big-assumption/toggle/${bigAssumptionId}`, {isActive});
}

function saveDevAssumptionApi(params) {
  return Api.post('/pod-development/add-assumption', params);
}

function updateDevAssumptionApi({value, entityId}) {
  return Api.post(`/pod-development/update-assumption/${value.bigAssumptionId}`, {value, entityId});
}

function deleteDevAssumptionApi({entityId, bigAssumptionId}) {
  return Api.delete(`/pod-development/remove-assumption/${bigAssumptionId}`, {entityId});
}

function saveDevExperimentApi(params) {
  return Api.post('/experiment/entry', params);
}

function updateDevExperimentApi({bigAssumptionId, value}) {
  return Api.post(`/experiment/entry/${value._id}`, {
    bigAssumptionId: bigAssumptionId,
    text: value.text,
  });
}

function deleteDevExperimentApi({entryId, bigAssumptionId}) {
  return Api.delete(`/experiment/entry/${entryId}`, {bigAssumptionId});
}

function toggleDevBigAssumptionApi({bigAssumptionId, isActive}) {
  return Api.post(`/big-assumption/toggle/${bigAssumptionId}`, {isActive});
}

function updateDevelopmentNoteApi(params) {
  return Api.post(`/pod-development/update-note/${params._id}`, params);
}

function saveDevelopmentNoteApi(params) {
  return Api.post('/pod-development/add-note', params);
}

function deleteDevelopmentNoteApi(params) {
  return Api.delete('/pod-development/remove-note', params);
}

function saveNoteApi(params) {
  return Api.post('/purpose/add-note', params);
}

function updateNoteApi(params) {
  return Api.post(`/purpose/update-note/${params._id}`, params);
}

function deleteNoteApi(params) {
  return Api.delete('/purpose/remove-note', params);
}

function updateChallengeNoteApi(params) {
  return Api.post(`/challenge/update-note/${params._id}`, params);
}

function saveChallengeNoteApi(params) {
  return Api.post('/challenge/add-note', params);
}

function deleteChallengeNoteApi(params) {
  return Api.delete('/challenge/remove-note', params);
}

function toggleExperimentApi({_id, isHidden}) {
  return Api.post(`/big-assumption/experiment/toggle/${_id}`, {isHidden})
}

function createNewExperimentApi(bigAssumptionId) {
  return Api.post(`/big-assumption/${bigAssumptionId}/experiments`);
}

function* getWorkout(action) {
  try {
    const {params: {id}} = action;
    const workout = yield call(getWorkoutApi, id);

    yield put({type: SET_USER_WORKOUT, workout});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteWorkout(action) {
  try {
    const {params} = action;

    yield call(deleteWorkoutApi, params);

    yield put({type: REMOVE_USER_WORKOUT, workoutId: params});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteWorkouts(action) {
  try {
    const {params} = action;
    const removedWorkouts = yield call(deleteWorkoutsApi, params);

    yield put({type: REMOVE_USER_WORKOUTS, params: {removedWorkouts, coachSprintId: params.coachSprintId}});
  } catch (error) {
    yield onError(error);
  }
}

function* updateWorkouts(action) {
  try {
    const {params} = action;
    const updatedWorkouts = yield call(updateWorkoutsApi, params);

    yield put({type: REPLACE_USER_WORKOUTS, params: {updatedWorkouts, coachSprintId: params.coachSprintId}});
  } catch (error) {
    yield onError(error);
  }
}

function* createWorkouts(action) {
  try {
    const {params} = action;
    const newWorkouts = yield call(createWorkoutsApi, params);

    yield put({type: ADD_USER_WORKOUTS, params: {newWorkouts, coachSprintId: params.coachSprintId}});
  } catch (error) {
    yield onError(error);
  }
}

function* getWorkouts() {
  try {
    const workouts = yield call(getWorkoutsApi);

    yield put({type: SET_USER_WORKOUTS, workouts});
  } catch (error) {
    yield onError(error);
  }
}

function* saveBigAssumption(action) {
  try {
    const {params} = action;
    let assumptions;

    if (!params.value.bigAssumptionId) {
      assumptions = yield call(saveAssumptionApi, params);
    } else {
      assumptions = yield call(updateAssumptionApi, {...params});
    }

    yield put({type: SET_WORKOUT_BIG_ASSUMPTION, assumptions, entityId: params.entityId});
  } catch (error) {
    yield onError(error);
  }
}

function* saveExperiment(action) {
  try {
    const {params} = action;
    const bigAssumption = yield call(saveExperimentApi, params);

    yield put({type: SET_WORKOUT_EXPERIMENT, bigAssumption, entityId: params.entityId});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteExperiment(action) {
  try {
    const {params} = action;
    const experiment = yield call(deleteExperimentApi, params);

    yield put({type: SET_UPDATED_WORKOUT_EXPERIMENT, experiment, entityId: params.entityId, bigAssumptionId: params.bigAssumptionId});
  } catch (error) {
    yield onError(error);
  }
}

function* updateExperiment(action) {
  try {
    const {params} = action;
    const bigAssumption = yield call(updateExperimentApi, params);

    yield put({type: SET_WORKOUT_EXPERIMENT, bigAssumption, entityId: params.entityId});
  } catch (error) {
    yield onError(error);
  }
}

function* toggleExperiment(action) {
  try {
    const {params} = action;
    const {isHidden} = yield call(toggleExperimentApi, params);
    
    yield put({
      type: SAVE_TOGGLE_WORKOUT_EXPERIMENT,
      experimentId: params._id,
      bigAssumptionId: params.bigAssumptionId,
      isHidden,
      entityId: params.entityId
    })
  } catch(error) {
    yield onError(error)
  }
}

function* createNewExperiments(action) {
  try {
    const {params} = action;
    
    const experiment = yield call(createNewExperimentApi, params.bigAssumptionId);

    yield put({type: UPDATE_WORKOUT_EXPERIMENTS, experiment, entityId: params.entityId})
  } catch(error) {
    yield onError(error)
  }
}

function* deleteAssumption(action) {
  try {
    const {params} = action;

    yield call(deleteAssumptionApi, params);
    yield put({type: REMOVE_WORKOUT_BIG_ASSUMPTION, ...params});
  } catch (error) {
    yield onError(error);
  }
}

function* toggleBigAssumption(action) {
  try {
    const {params} = action;
    const isActive = yield call(toggleBigAssumptionApi, params);

    yield put({
      type: UPDATE_WORKOUT_BIG_ASSUMPTION,
      assumptionId: params.bigAssumptionId,
      entityId: params.entityId,
      field: 'isActive',
      value: isActive,
    });
  } catch (error) {
    yield onError(error);
  }
}

function* saveNote(action) {
  try {
    const {params} = action;
    let notes;

    if (params._id) {
      notes = yield call(updateNoteApi, params);
    } else {
      notes = yield call(saveNoteApi, params);
    }

    yield put({type: SET_WORKOUT_PURPOSE_NOTES, notes, field: params.field});
  } catch (error) {
    yield onError(error);
  }
}

function* saveQuestion(action) {
  try {
    const {params} = action;

    yield call(saveQuestionApi, params);
    yield put({type: SET_WORKOUT_CHALLENGE_QUESTION, ...params});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteNote(action) {
  try {
    const {params} = action;

    yield call(deleteNoteApi, params);
    yield put({type: REMOVE_WORKOUT_NOTE, ...params});
  } catch (error) {
    yield onError(error);
  }
}

function* saveChallengeNote(action) {
  try {
    const {params} = action;
    let notes;

    if (params._id) {
      notes = yield call(updateChallengeNoteApi, params);
    } else {
      notes = yield call(saveChallengeNoteApi, params);
    }

    yield put({type: SET_WORKOUT_CHALLENGE_NOTES, notes, field: params.field, entityId: params.entityId});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteChallengeNote(action) {
  try {
    const {params} = action;

    yield call(deleteChallengeNoteApi, params);
    yield put({type: REMOVE_WORKOUT_CHALLENGE_NOTE, ...params});
  } catch (error) {
    yield onError(error);
  }
}

function* onError(error) {
  notify({
    error,
    context: { component: 'Workout Sagas', severity: 'error' },
  });

  yield put({
    type: ON_MESSAGE,
    message: error,
    messageType: 'error',
  });
}

function* saveDevBigAssumption(action) {
  try {
    const {params} = action;
    let assumptions;

    if (!params.value.bigAssumptionId) {
      assumptions = yield call(saveDevAssumptionApi, params);
    } else {
      assumptions = yield call(updateDevAssumptionApi, {...params});
    }

    yield put({type: SET_POD_BIG_ASSUMPTION, assumptions, entityId: params.entityId});
  } catch (error) {
    yield onError(error);
  }
}

function* saveDevExperiment(action) {
  try {
    const {params} = action;
    const bigAssumption = yield call(saveDevExperimentApi, params);

    yield put({type: SET_POD_EXPERIMENT, bigAssumption, entityId: params.entityId});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteDevExperiment(action) {
  try {
    const {params} = action;
    const bigAssumption = yield call(deleteDevExperimentApi, params);

    yield put({type: SET_POD_EXPERIMENT, bigAssumption, entityId: params.entityId});
  } catch (error) {
    yield onError(error);
  }
}

function* updateDevExperiment(action) {
  try {
    const {params} = action;
    const bigAssumption = yield call(updateDevExperimentApi, params);

    yield put({type: SET_POD_EXPERIMENT, bigAssumption, entityId: params.entityId});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteDevAssumption(action) {
  try {
    const {params} = action;

    yield call(deleteDevAssumptionApi, params);
    yield put({type: REMOVE_POD_BIG_ASSUMPTION, ...params});
  } catch (error) {
    yield onError(error);
  }
}

function* toggleDevBigAssumption(action) {
  try {
    const {params} = action;
    const isActive = yield call(toggleDevBigAssumptionApi, params);

    yield put({
      type: UPDATE_POD_BIG_ASSUMPTION,
      assumptionId: params.bigAssumptionId,
      entityId: params.entityId,
      field: 'isActive',
      value: isActive,
    });
  } catch (error) {
    yield onError(error);
  }
}

function* saveDevelopmentNote(action) {
  try {
    const {params} = action;
    let notes;

    if (params._id) {
      notes = yield call(updateDevelopmentNoteApi, params);
    } else {
      notes = yield call(saveDevelopmentNoteApi, params);
    }

    yield put({type: SET_POD_DEVELOPMENT_NOTES, notes, field: params.field, entityId: params.entityId});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteDevelopmentNote(action) {
  try {
    const {params} = action;

    yield call(deleteDevelopmentNoteApi, params);
    yield put({type: REMOVE_POD_DEVELOPMENT_NOTE, ...params});
  } catch (error) {
    yield onError(error);
  }
}

export function* getUserWorkoutWatcher() {
  yield takeLatest(GET_USER_WORKOUT, getWorkout);
}

export function* deleteUserWorkoutWatcher() {
  yield takeLatest(DELETE_USER_WORKOUT, deleteWorkout);
}

export function* deleteUserWorkoutsWatcher() {
  yield takeLatest(DELETE_USER_WORKOUTS, deleteWorkouts);
}

export function* updateUserWorkoutsWatcher() {
  yield takeLatest(UPDATE_USER_WORKOUTS, updateWorkouts);
}

export function* createUserWorkoutsWatcher() {
  yield takeLatest(CREATE_USER_WORKOUTS, createWorkouts);
}

export function* getUserWorkoutsWatcher() {
  yield takeLatest(GET_USER_WORKOUTS, getWorkouts);
}

export function* saveWorkoutAssumptionWatcher() {
  yield takeLatest(SAVE_WORKOUT_BIG_ASSUMPTION, saveBigAssumption);
}

export function* deleteWorkoutAssumptionWatcher() {
  yield takeLatest(DELETE_WORKOUT_BIG_ASSUMPTION, deleteAssumption);
}

export function* saveWorkoutExperimentWatcher() {
  yield takeLatest(SAVE_WORKOUT_EXPERIMENT, saveExperiment);
}

export function* updateWorkoutExperimentWatcher() {
  yield takeLatest(UPDATE_WORKOUT_EXPERIMENT, updateExperiment);
}

export function* deleteWorkoutExperimentWatcher() {
  yield takeLatest(DELETE_WORKOUT_EXPERIMENT, deleteExperiment);
}

export function* toggleWorkoutAssumptionWatcher() {
  yield takeLatest(TOGGLE_WORKOUT_BIG_ASSUMPTION, toggleBigAssumption);
}

export function* saveWorkoutNoteWatcher() {
  yield takeLatest(SAVE_WORKOUT_NOTE, saveNote);
}

export function* saveWorkoutQuestionWatcher() {
  yield takeLatest(SAVE_WORKOUT_QUESTION, saveQuestion);
}

export function* deleteWorkoutNoteWatcher() {
  yield takeLatest(DELETE_WORKOUT_NOTE, deleteNote);
}

export function* saveWorkoutNoteChallengeWatcher() {
  yield takeLatest(SAVE_WORKOUT_CHALLENGE_NOTE, saveChallengeNote);
}

export function* deleteWorkoutNoteChallengeWatcher() {
  yield takeLatest(DELETE_WORKOUT_CHALLENGE_NOTE, deleteChallengeNote);
}

//Development
export function* savePodAssumptionWatcher() {
  yield takeLatest(SAVE_POD_BIG_ASSUMPTION, saveDevBigAssumption);
}

export function* deletePodAssumptionWatcher() {
  yield takeLatest(DELETE_POD_BIG_ASSUMPTION, deleteDevAssumption);
}

export function* savePodExperimentWatcher() {
  yield takeLatest(SAVE_POD_EXPERIMENT, saveDevExperiment);
}

export function* updatePodExperimentWatcher() {
  yield takeLatest(UPDATE_POD_EXPERIMENT, updateDevExperiment);
}

export function* deletePodExperimentWatcher() {
  yield takeLatest(DELETE_POD_EXPERIMENT, deleteDevExperiment);
}

export function* togglePodAssumptionWatcher() {
  yield takeLatest(TOGGLE_POD_BIG_ASSUMPTION, toggleDevBigAssumption);
}

export function* savePodNoteDevelopmentWatcher() {
  yield takeLatest(SAVE_POD_DEVELOPMENT_NOTE, saveDevelopmentNote);
}

export function* deletePodNoteDevelopmentWatcher() {
  yield takeLatest(DELETE_POD_DEVELOPMENT_NOTE, deleteDevelopmentNote);
}

export function* toggleWorkoutExperimentWatcher() {
  yield takeLatest(TOGGLE_WORKOUT_EXPERIMENT, toggleExperiment)
}

export function* createNewWorkoutExperimentWatcher() {
  yield takeLatest(CREATE_WORKOUT_EXPERIMENT, createNewExperiments);
}
