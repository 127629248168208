import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import React, { Suspense } from 'react';

const LazyLoader = (Component,fallback) => {
  return (props) => (
    <Suspense fallback={fallback}>
      <ErrorBoundary context={{severity: 'critical', component: 'APP RENDER'}} fallback>
        <Component {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

export default LazyLoader