import { Notifier } from '@airbrake/browser';

const {REACT_APP_AIRBRAKE_PROJECT_ID, REACT_APP_AIRBRAKE_PROJECT_KEY} = process.env;

let airbrake;

if (REACT_APP_AIRBRAKE_PROJECT_ID && REACT_APP_AIRBRAKE_PROJECT_KEY) {
  airbrake = new Notifier({
    projectId: parseInt(process.env.REACT_APP_AIRBRAKE_PROJECT_ID, 10),
    projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY,
    environment: process.env.NODE_ENV,
  });
}


export const notify = (params) => {
  if (airbrake) {
    airbrake.notify(params);
  }
};
