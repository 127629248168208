import {createReducer} from '../utils';
import {
  SET_USER_NFF,
  SET_USER_NFFS,
} from '../actions/types';

const initialState = {
  nffs: [],
};

const nffReducer = createReducer(initialState, {
  [SET_USER_NFFS]: (state, {nffs}) => {
    return {
      ...state,
      nffs,
    };
  },
  [SET_USER_NFF]: (state, {currentNff}) => {
    return {
      ...state,
      currentNff,
    };
  },
});

export default nffReducer;
