// @flow
import {push, replace, goBack} from 'connected-react-router';
import {RTL_DIRECTION_LANGUAGES} from '../constants';
import {signOut} from '../actions/auth'
import {store} from '../store';

class ApiError extends Error {
  constructor(message, status) {
    super();
    this.message = message;
    this.status = status;
  }
}

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers[action.type]) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}

const rejectApiCall = (status, data) => {
  if (!navigator.onLine) {
    return Promise.reject({
      status,
      message: 'The application is currently offline. Please check you internet connection before submit.',
    });;
  }
  return Promise.reject({
    status,
    message: data.message || 'Unknown Api Error',
  });
}

export function wrapApiErrors(error, route) {
  const {response = {}} = error;
  const {data, status} = response;

  if (status === 401) {
    store.dispatch(signOut());
    forwardTo({
      location: '/auth/login',
      needReplace: true
    });

    return rejectApiCall(status, {message: data.message || 'Not Authorized Action'});
  }

  if (status === 400) {
    return rejectApiCall(status, {message: data.message || 'Bad Request'});
  }

  if (status === 404) {
    return forwardTo({
      location: '/404',
      needReplace: true,
      state: {
        data: {
          message: route.includes('sprint') || route.includes('workout') || route.includes('nff') ? 'This page is set to Private.' : null,
        },
      }
    });
  }

  if (!navigator.onLine) {
    return rejectApiCall(status,
      {message: 'The application is currently offline. Please check you internet connection before submit.',
    });;
  }

  if (!data) {
    throw new Error('Unknown Api Error');
  }
  const {message: respErrorText} = data;
  throw new ApiError(respErrorText, status);
}

export function forwardTo({location, needReplace, state = {}}) {
  const action = needReplace ? replace : push;
  if (location === 'back') {
    return store.dispatch(goBack());
  }
  return store.dispatch(action(location, state));
}

export function getUrlParams(search) {
  const pureSearch = search.slice(search.indexOf('?') + 1);
  if (!pureSearch) {
    return {};
  }
  const hashes = pureSearch.split('&');
  return hashes.reduce((params, hash) => {
    const [key, val] = hash.split('=');
    return {
      ...params,
      [key]: decodeURIComponent(val),
    };
  }, {});
}

export function htmlEscape(str) {
  return str
    .replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
}

export function moveCursorToEnd(event) {
  const el = event.target;

  if (typeof el.selectionStart == "number") {
    el.selectionStart = el.selectionEnd = el.value.length;
  } else if (typeof el.createTextRange != "undefined") {
    el.focus();
    const range = el.createTextRange();
    range.collapse(false);
    range.select();
  }
}

export function getContentDirection(langCode) {
  return RTL_DIRECTION_LANGUAGES.includes(langCode) ? 'rtl' : 'ltr';
}

export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
