import {put, call, takeLatest} from 'redux-saga/effects';
import Api from '../../utils/api';
import {
  GET_USER_PODS,
  SET_USER_PODS,
  GET_USER_POD,
  SET_USER_POD,
  ON_MESSAGE,
  SET_POD_START_NOTES,
  SAVE_POD_NOTE,
  DELETE_POD_NOTE,
  REMOVE_POD_NOTE,
} from '../../actions/types';

function getPodsApi() {
  return Api.get('/pod');
}

function getPodApi(workoutId) {
  return Api.get(`/pod/${workoutId}`);
}

function saveNoteApi(params) {
  return Api.post('/pod-start/add-note', params);
}

function updateNoteApi(params) {
  return Api.post(`/pod-start/update-note/${params._id}`, params);
}

function deleteNoteApi(params) {
  return Api.delete('/pod-start/remove-note', params);
}

function* getPod(action) {
  try {
    const {params: {id}} = action;
    const pod = yield call(getPodApi, id);

    yield put({type: SET_USER_POD, pod});
  } catch (error) {
    yield onError(error);
  }
}

function* getPods() {
  try {
    const pods = yield call(getPodsApi);

    yield put({type: SET_USER_PODS, pods});
  } catch (error) {
    yield onError(error);
  }
}

function* saveNote(action) {
  try {
    const {params} = action;
    let notes;

    if (params._id) {
      notes = yield call(updateNoteApi, params);
    } else {
      notes = yield call(saveNoteApi, params);
    }

    yield put({type: SET_POD_START_NOTES, notes, field: params.field});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteNote(action) {
  try {
    const {params} = action;

    yield call(deleteNoteApi, params);
    yield put({type: REMOVE_POD_NOTE, ...params});
  } catch (error) {
    yield onError(error);
  }
}

function* onError(error) {
  yield put({
    type: ON_MESSAGE,
    message: error,
    messageType: 'error',
  });
}

export function* getUserPodWatcher() {
  yield takeLatest(GET_USER_POD, getPod);
}

export function* getUserPodsWatcher() {
  yield takeLatest(GET_USER_PODS, getPods);
}

export function* savePodNoteWatcher() {
  yield takeLatest(SAVE_POD_NOTE, saveNote);
}

export function* deletePodNoteWatcher() {
  yield takeLatest(DELETE_POD_NOTE, deleteNote);
}
