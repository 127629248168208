import {put, call, takeLatest} from 'redux-saga/effects';
import Api from '../../utils/api';
import {
  TOGGLE_DEV_WORKOUT_EXPERIMENT,
  CREATE_DEV_WORKOUT_EXPERIMENT,
  
  GET_USER_DEV_WORKOUTS,
  SET_USER_DEV_WORKOUTS,
  GET_USER_DEV_WORKOUT,
  REMOVE_USER_DEV_WORKOUT,
  SET_USER_DEV_WORKOUT,
  UPDATE_USER_DEV_WORKOUT,
  ON_MESSAGE,
  SAVE_DEV_WORKOUT_BIG_ASSUMPTION,
  SET_DEV_WORKOUT_BIG_ASSUMPTION,
  DELETE_DEV_WORKOUT_BIG_ASSUMPTION,
  REMOVE_DEV_WORKOUT_BIG_ASSUMPTION,
  SAVE_DEV_WORKOUT_EXPERIMENT,
  SET_DEV_WORKOUT_EXPERIMENT,
  UPDATE_DEV_WORKOUT_EXPERIMENT,
  UPDATE_DEV_WORKOUT_BIG_ASSUMPTION,
  TOGGLE_DEV_WORKOUT_BIG_ASSUMPTION,
  DELETE_DEV_WORKOUT_EXPERIMENT,
  
  DELETE_USER_DEV_WORKOUT,
  DELETE_USER_DEV_WORKOUTS,
  REMOVE_USER_DEV_WORKOUTS,
  CREATE_USER_DEV_WORKOUTS,
  ADD_USER_DEV_WORKOUTS,
  UPDATE_USER_DEV_WORKOUTS,
  REPLACE_USER_DEV_WORKOUTS,
  SAVE_TOGGLE_DEV_WORKOUT_EXPERIMENT,
  UPDATE_DEV_WORKOUT_EXPERIMENTS,
  SET_UPDATED_DEV_WORKOUT_EXPERIMENT,

  SAVE_DEV_WORKOUT_TITLE,
  CREATE_DEV_WORKOUT_ENTRY,
  UPDATE_DEV_WORKOUT_ENTRY,
  DELETE_DEV_WORKOUT_ENTRY,
  UPDATE_DEV_WORKOUT_ENTRIES_ORDER,
  ADD_DEV_WORKOUT_CHALLENGE,
  RESET_USER_DEV_WORKOUTS,

  UPDATE_WORKOUT_TAKING_STOCK_QUESTION,
  DELETE_WORKOUT_TAKING_STOCK_QUESTION_ENTRY,
  SET_QUESTION_FOR_WORKOUT_TAKING_STOCK,
} from '../../actions/types';
import {notify} from '../../utils/airbrake';

function getUserDevWorkoutsApi() {
  return Api.get('/dev-workout');
}

function getDevWorkoutApi(workoutId) {
  return Api.get(`/workout-challenge/${workoutId}`);
}

function deleteDevWorkoutApi(workoutId) {
  return Api.delete(`/dev-workout/${workoutId}`);
}

function deleteDevWorkoutsApi(params) {
  return Api.delete('/dev-workout/remove', params);
}

function updateDevWorkoutsApi(params) {
  return Api.post('/dev-workout/update', params);
}

function createDevWorkoutsApi(params) {
  return Api.post('/dev-workout', params);
}

function saveAssumptionApi(params) {
  return Api.post('/workout-challenge/add-assumption', params);
}

function updateAssumptionApi({value, entityId}) {
  return Api.post(`/workout-challenge/update-assumption/${value.bigAssumptionId}`, {value, entityId});
}

function deleteAssumptionApi({entityId, bigAssumptionId}) {
  return Api.delete(`/workout-challenge/remove-assumption/${bigAssumptionId}`, {entityId});
}

function saveExperimentApi(params) {
  return Api.post('/experiment/entry', params);
}

function deleteExperimentApi({experimentId, id}) {
  return Api.delete(`/big-assumption/${id}/experiment`, {experimentId});
}

function toggleBigAssumptionApi({bigAssumptionId, isActive}) {
  return Api.post(`/big-assumption/toggle/${bigAssumptionId}`, {isActive});
}

function updateTitleApi({id, ...params}) {
  return Api.post(`/workout-challenge/${id}/title`, params);
}

function updateUserWorkoutApi({id, ...params}) {
  return Api.post(`/workout-challenge/${id}/update`, params);
}

function createDevWorkoutEntryApi({id, ...params}) {
  return Api.post(`/workout-challenge/${id}/entry`, params);
}

function updateDevWorkoutEntryApi({id, entryId, ...params}) {
  return Api.post(`/workout-challenge/${id}/entry/${entryId}`, params);
}

function deleteDevWorkoutEntryApi({id, entryId, ...params}) {
  return Api.delete(`/workout-challenge/${id}/entry/${entryId}`, params);
}

function updateDevWorkoutEntriesOrderApi({id, ...params}) {
  return Api.post(`/workout-challenge/${id}/entries/reorder`, params)
}

function addChallengeApi(id) {
  return Api.post(`/dev-workout/${id}/add-challenge`);
}

function updateDevWorkoutExperimentApi({bigAssumptionId, value}) {
  return Api.post(`/experiment/entry/${value._id}`, {
    bigAssumptionId: bigAssumptionId,
    text: value.text,
  });
}

function toggleDevWorkoutBigAssumptionApi({bigAssumptionId, isActive}) {
  return Api.post(`/big-assumption/toggle/${bigAssumptionId}`, {isActive});
}

function toggleExperimentApi({_id, isHidden}) {
  return Api.post(`/big-assumption/experiment/toggle/${_id}`, {isHidden})
}

function createNewExperimentApi(bigAssumptionId) {
  return Api.post(`/big-assumption/${bigAssumptionId}/experiments`);
}

function updateTakingStockQuestionApi({takingStockId, value}) {
  return Api.post(`/workout-challenge/taking-stock/${takingStockId}`, {value});
}

function deleteTakingStockQuestionEntryApi({takingStockId, value}) {
  return Api.post(`/workout-challenge/taking-stock/${takingStockId}/delete/entry`, {value});
}

function* updateTakingStockQuestion(action) {
  try {
    const {params} = action;
    const newQuestionValue = yield call(updateTakingStockQuestionApi, params);

    yield put({
      type: SET_QUESTION_FOR_WORKOUT_TAKING_STOCK,
      value: {[params.value.field]: newQuestionValue},
    });
  } catch (error) {
    yield onError(error);
  }
}

function* deleteTakingStockQuestionEntry(action) {
  try {
    const {params} = action;
    const newQuestionValue = yield call(
      deleteTakingStockQuestionEntryApi,
      params,
    );

    yield put({
      type: SET_QUESTION_FOR_WORKOUT_TAKING_STOCK,
      value: {[params.value.field]: newQuestionValue},
    });
  } catch (error) {
    yield onError(error);
  }
}

function* getDevWorkout(action) {
  try {
    const {params: {id}} = action;
    const currentWorkout = yield call(getDevWorkoutApi, id);

    yield put({type: SET_USER_DEV_WORKOUT, currentWorkout});

  } catch (error) {
    yield onError(error);
  }
}

function* deleteDevWorkout(action) {
  try {
    const {params} = action;

    yield call(deleteDevWorkoutApi, params);

    yield put({type: REMOVE_USER_DEV_WORKOUT, workoutId: params});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteDevWorkouts(action) {
  try {
    const {params} = action;
    const removedWorkouts = yield call(deleteDevWorkoutsApi, params);

    yield put({type: REMOVE_USER_DEV_WORKOUTS, params: {removedWorkouts, coachSprintId: params.coachSprintId}});
  } catch (error) {
    yield onError(error);
  }
}

function* updateDevWorkouts(action) {
  try {
    const {params} = action;
    const updatedWorkouts = yield call(updateDevWorkoutsApi, params);

    yield put({type: REPLACE_USER_DEV_WORKOUTS, params: {updatedWorkouts, coachSprintId: params.coachSprintId}});
  } catch (error) {
    yield onError(error);
  }
}

function* createDevWorkouts(action) {
  try {
    const {params} = action;
    const newWorkouts = yield call(createDevWorkoutsApi, params);

    yield put({type: ADD_USER_DEV_WORKOUTS, params: {newWorkouts, coachSprintId: params.coachSprintId}});
  } catch (error) {
    yield onError(error);
  }
}

function* getUserDevWorkouts() {
  try {
    const workouts = yield call(getUserDevWorkoutsApi);

    yield put({type: SET_USER_DEV_WORKOUTS, workouts});
  } catch (error) {
    yield onError(error);
  }
}

function* saveBigAssumption(action) {
  try {
    const {params} = action;
    let assumptions;

    if (!params.value.bigAssumptionId) {
      assumptions = yield call(saveAssumptionApi, params);
    } else {
      assumptions = yield call(updateAssumptionApi, {...params});
    }

    yield put({type: SET_DEV_WORKOUT_BIG_ASSUMPTION, assumptions, entityId: params.entityId});
  } catch (error) {
    yield onError(error);
  }
}

function* saveExperiment(action) {
  try {
    const {params} = action;
    const bigAssumption = yield call(saveExperimentApi, params);

    yield put({type: SET_DEV_WORKOUT_EXPERIMENT, bigAssumption, entityId: params.entityId});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteDevWorkoutExperiment(action) {
  try {
    const {params} = action;
    const experiment = yield call(deleteExperimentApi, params);

    yield put({type: SET_UPDATED_DEV_WORKOUT_EXPERIMENT, experiment, entityId: params.entityId, bigAssumptionId: params.bigAssumptionId});
  } catch (error) {
    yield onError(error);
  }
}

function* toggleExperiment(action) {
  try {
    const {params} = action;
    const {isHidden} = yield call(toggleExperimentApi, params);
    
    yield put({
      type: SAVE_TOGGLE_DEV_WORKOUT_EXPERIMENT,
      experimentId: params._id,
      bigAssumptionId: params.bigAssumptionId,
      isHidden,
      entityId: params.entityId
    })
  } catch(error) {
    yield onError(error)
  }
}

function* createNewExperiments(action) {
  try {
    const {params} = action;
    
    const experiment = yield call(createNewExperimentApi, params.bigAssumptionId);

    yield put({type: UPDATE_DEV_WORKOUT_EXPERIMENTS, experiment, entityId: params.entityId})
  } catch(error) {
    yield onError(error)
  }
}

function* deleteAssumption(action) {
  try {
    const {params} = action;

    yield call(deleteAssumptionApi, params);
    yield put({type: REMOVE_DEV_WORKOUT_BIG_ASSUMPTION, ...params});
  } catch (error) {
    yield onError(error);
  }
}

function* toggleBigAssumption(action) {
  try {
    const {params} = action;
    const isActive = yield call(toggleBigAssumptionApi, params);

    yield put({
      type: UPDATE_DEV_WORKOUT_BIG_ASSUMPTION,
      assumptionId: params.bigAssumptionId,
      entityId: params.entityId,
      field: 'isActive',
      value: isActive,
    });
  } catch (error) {
    yield onError(error);
  }
}

function* createDevWorkoutEntry(action) {
  try {
    const {params} = action;

    const currentWorkout = yield call(createDevWorkoutEntryApi, params);

    yield put({type: SET_USER_DEV_WORKOUT, currentWorkout});
  } catch (error) {
    yield onError(error);
  }
}

function* updateDevWorkoutEntry(action) {
  try {
    const {params} = action;
    const currentWorkout = yield call(updateDevWorkoutEntryApi, params);
    
    yield put({type: SET_USER_DEV_WORKOUT, currentWorkout});
  } catch (error) {
    yield onError(error);
  }
}

function* updateTitle(action) {
  try {
    const {params} = action;
    const currentWorkout = yield call(updateTitleApi, params);
    
    yield put({type: SET_USER_DEV_WORKOUT, currentWorkout});
  } catch (error) {
    yield onError(error);
  }
}

function* updateUserWorkout(action) {
  try {
    const {params} = action;
    const currentWorkout = yield call(updateUserWorkoutApi, params);
    
    yield put({type: SET_USER_DEV_WORKOUT, currentWorkout, isUpdate: true});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteDevWorkoutEntry(action) {
  try {
    const {params} = action;
    const currentWorkout = yield call(deleteDevWorkoutEntryApi, params);

    yield put({type: SET_USER_DEV_WORKOUT, currentWorkout});
  } catch (error) {
    yield onError(error);
  }
}

function* updateDevWorkoutEntriesOrder(action) {
  try {
    const {params} = action; 
    const currentWorkout = yield call(updateDevWorkoutEntriesOrderApi, params);

    yield put({type: SET_USER_DEV_WORKOUT, currentWorkout});
  } catch (error) {
    yield onError(error);
  }
}

function* addChallenge(action) {
  try {
    const {params: {_id}} = action;
    const workouts = yield call(addChallengeApi, _id);

    yield put({type: SET_USER_DEV_WORKOUTS, workouts});
  } catch (error) {
    yield onError(error);
  }
}

function* onError(error) {
  notify({
    error,
    context: { component: 'Workout Sagas', severity: 'error' },
  });

  yield put({
    type: ON_MESSAGE,
    message: error,
    messageType: 'error',
  });
}

function* saveDevWorkoutBigAssumption(action) {
  try {
    const {params} = action;
    let assumptions;

    if (!params.value.bigAssumptionId) {
      assumptions = yield call(saveAssumptionApi, params);
    } else {
      assumptions = yield call(updateAssumptionApi, {...params});
    }

    yield put({type: SET_DEV_WORKOUT_BIG_ASSUMPTION, assumptions, entityId: params.entityId});
  } catch (error) {
    yield onError(error);
  }
}


function* updateDevWorkoutExperiment(action) {
  try {
    const {params} = action;
    const bigAssumption = yield call(updateDevWorkoutExperimentApi, params);

    yield put({type: SET_DEV_WORKOUT_EXPERIMENT, bigAssumption, entityId: params.entityId});
  } catch (error) {
    yield onError(error);
  }
}

function* toggleDevWorkoutBigAssumption(action) {
  try {
    const {params} = action;
    const isActive = yield call(toggleDevWorkoutBigAssumptionApi, params);

    yield put({
      type: UPDATE_DEV_WORKOUT_BIG_ASSUMPTION,
      assumptionId: params.bigAssumptionId,
      entityId: params.entityId,
      field: 'isActive',
      value: isActive,
    });
  } catch (error) {
    yield onError(error);
  }
}
function* resetWorkouts() {
  try {
    yield put({type: SET_USER_DEV_WORKOUT, currentWorkout: null});
  } catch (error) {
    yield onError(error);
  }
}

export function* getUserDevWorkoutWatcher() {
  yield takeLatest(GET_USER_DEV_WORKOUT, getDevWorkout);
}

export function* deleteUserDevWorkoutWatcher() {
  yield takeLatest(DELETE_USER_DEV_WORKOUT, deleteDevWorkout);
}

export function* deleteUserDevWorkoutsWatcher() {
  yield takeLatest(DELETE_USER_DEV_WORKOUTS, deleteDevWorkouts);
}

export function* updateUserDevWorkoutsWatcher() {
  yield takeLatest(UPDATE_USER_DEV_WORKOUTS, updateDevWorkouts);
}

export function* createUserDevWorkoutsWatcher() {
  yield takeLatest(CREATE_USER_DEV_WORKOUTS, createDevWorkouts);
}

export function* getUserDevWorkoutsWatcher() {
  yield takeLatest(GET_USER_DEV_WORKOUTS, getUserDevWorkouts);
}

export function* saveWorkoutAssumptionWatcher() {
  yield takeLatest(SAVE_DEV_WORKOUT_BIG_ASSUMPTION, saveBigAssumption);
}

export function* deleteDevWorkoutAssumptionWatcher() {
  yield takeLatest(DELETE_DEV_WORKOUT_BIG_ASSUMPTION, deleteAssumption);
}

export function* saveDevWorkoutExperimentWatcher() {
  yield takeLatest(SAVE_DEV_WORKOUT_EXPERIMENT, saveExperiment);
}

export function* updateDevWorkoutExperimentWatcher() {
  yield takeLatest(UPDATE_DEV_WORKOUT_EXPERIMENT, updateDevWorkoutExperiment);
}

export function* toggleWorkoutAssumptionWatcher() {
  yield takeLatest(TOGGLE_DEV_WORKOUT_BIG_ASSUMPTION, toggleBigAssumption);
}

export function* saveDevWorkoutAssumptionWatcher() {
  yield takeLatest(SAVE_DEV_WORKOUT_BIG_ASSUMPTION, saveDevWorkoutBigAssumption);
}

export function* deleteDevWorkoutExperimentWatcher() {
  yield takeLatest(DELETE_DEV_WORKOUT_EXPERIMENT, deleteDevWorkoutExperiment);
}

export function* toggleDevWorkoutAssumptionWatcher() {
  yield takeLatest(TOGGLE_DEV_WORKOUT_BIG_ASSUMPTION, toggleDevWorkoutBigAssumption);
}

export function* toggleDevWorkoutExperimentWatcher() {
  yield takeLatest(TOGGLE_DEV_WORKOUT_EXPERIMENT, toggleExperiment)
}

export function* createNewDevWorkoutExperimentWatcher() {
  yield takeLatest(CREATE_DEV_WORKOUT_EXPERIMENT, createNewExperiments);
}

export function* updateTitleWatcher() {
  yield takeLatest(SAVE_DEV_WORKOUT_TITLE, updateTitle);
}
export function* createDevWorkoutEntryWatcher() {
  yield takeLatest(CREATE_DEV_WORKOUT_ENTRY, createDevWorkoutEntry);
}

export function* updateDevWorkoutEntryWatcher() {
  yield takeLatest(UPDATE_DEV_WORKOUT_ENTRY, updateDevWorkoutEntry);
}

export function* deleteDevWorkoutEntryWatcher() {
  yield takeLatest(DELETE_DEV_WORKOUT_ENTRY, deleteDevWorkoutEntry);
}

export function* updateDevWorkoutEntriesOrderWatcher() {
  yield takeLatest(UPDATE_DEV_WORKOUT_ENTRIES_ORDER, updateDevWorkoutEntriesOrder);
}

export function* addNewChallengeWatcher() {
  yield takeLatest(ADD_DEV_WORKOUT_CHALLENGE, addChallenge);
}

export function* resetWorkoutsWatcher() {
  yield takeLatest(RESET_USER_DEV_WORKOUTS, resetWorkouts);
}

export function* updateUserWorkoutWatcher() {
  yield takeLatest(UPDATE_USER_DEV_WORKOUT, updateUserWorkout);
}


export function* updateWorkoutTakingStockQuestionWatcher() {
  yield takeLatest(UPDATE_WORKOUT_TAKING_STOCK_QUESTION, updateTakingStockQuestion);
}

export function* deleteWorkoutTakingStockQuestionEntryWatcher() {
  yield takeLatest(
    DELETE_WORKOUT_TAKING_STOCK_QUESTION_ENTRY,
    deleteTakingStockQuestionEntry,
  );
}
