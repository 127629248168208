import { lazy } from 'react';

const lazyWithRetry = (componentImport) => 
  lazy(async () => {
    try {
      return await componentImport();
    } catch (error) {
      if (error?.name === 'ChunkLoadError') {
        return window.location.reload();
      }

      return error;
    }
  });

export default lazyWithRetry