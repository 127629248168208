import {createContext} from 'react';

const AppContext = createContext({
  mainHeading: '',
  contentDirection: ''
});

AppContext.displayName = 'AppContext';

export default AppContext;
