import {createReducer} from '../utils';
import {ON_SECTION_CHANGE} from '../actions/types';

const initialState = {
  section: 1,
};

const dashboardReducer = createReducer(initialState, {
  [ON_SECTION_CHANGE]: (state, {section}) => ({
    ...state,
    section
  }),
});

export default dashboardReducer;
