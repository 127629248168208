import ReactDOM from 'react-dom';
import React from 'react';
import App from './App';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFlask, faBars, faCircle, faArrowCircleRight, faCheck, faArrowsAlt} from '@fortawesome/free-solid-svg-icons';
// eslint-disable-next-line
import locals from './i18n';
import './scss/styles.scss';


library.add(faFlask, faBars, faCircle, faArrowCircleRight, faCheck, faArrowsAlt);

const rootEl = document.getElementById('root');

ReactDOM.render(<App />, rootEl);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;

    ReactDOM.render(<NextApp />, rootEl);
  });
}
