import {put, call, takeLatest} from 'redux-saga/effects';
import Api from '../../utils/api';
import {
  REMOVE_USER_NFF,
  ON_MESSAGE,
  REMOVE_USER_NFFS,
  
  ADD_USER_NFFS,
  SET_USER_NFFS,
  SET_USER_NFF,
  ADD_NEW_FORMULA,
  
  REPLACE_USER_NFFS,
  UPDATE_USER_NFFS,
  DELETE_USER_NFFS,
  CREATE_USER_NFFS,
  GET_USER_NFFS,
  GET_USER_NFF,
  DELETE_USER_NFF,
  SAVE_USER_NFF_TITLE,
  // GET_USER_FORMULA,
  UPDATE_NFF_TOPIC,
  REPLACE_USER_FROMULA,
  UPDATE_USER_FROMULA,

  CREATE_FORMULA_ENTRY,
  UPDATE_FORMULA_ENTRY,
  DELETE_FORMULA_ENTRY,
  UPDATE_FORMULA_ENTRIES_ORDER,
  TOGGLE_FORMULA,
  DELETE_FORMULA,
  CREATE_USER_NFF,
  UPDATE_USER_NFF,
  RESET_USER_NFFS,
} from '../../actions/types';
import {notify} from '../../utils/airbrake';

function* onSuccess(message) {
  yield put({
    type: ON_MESSAGE,
    message: message,
    messageType: 'success',
  });
}

function getNffsApi() {
  return Api.get('/nff');
}

function getUserNffApi(nffId) {
  return Api.get(`/nff/${nffId}`);
}

// function getFormulaApi(formulaId) {
//   return Api.get(`/formula/${formulaId}`);
// }
function addFormulaApi(nffId) {
  return Api.post(`/nff/${nffId}/add-formula`);
}
function createNffsApi(params) {
  return Api.post('/nff', params);
}

function createNffApi(params) {
  return Api.post('/nff/create-one', params);
}

function deleteNffApi(nffId) {
  return Api.delete(`/nff/${nffId}`);
}

function deleteNffsApi(params) {
  return Api.delete('/nff/remove', params);
}

function updateNffsApi(params) {
  return Api.post('/nff/update', params);
}

function updateFromulaApi(params) {
  return Api.post('/formula/update', params);
}

function setNffTitleApi({id, ...params}) {
  return Api.post(`/formula/${id}/title`, params);
}

function createFormulaEntryApi({id, ...params}) {
  return Api.post(`/formula/${id}/entry`, params);
}

function updateFormulaEntryApi({id, entryId, ...params}) {
  return Api.post(`/formula/${id}/entry/${entryId}`, params);
}
function updateTopicApi({id, ...params}) {
  return Api.post(`/nff/${id}/topic`, params);
}

function deleteFormulaEntryApi({id, entryId, ...params}) {
  return Api.delete(`/formula/${id}/entry/${entryId}`, params);
}

function toggleFormulaApi({id, ...params}) {
  return Api.post(`/formula/${id}/toggle`, params);
}

function deleteFormulaApi({id, ...params}) {
  return Api.delete(`/formula/${id}`, params);
}

function updateNffApi({nffId, ...params}) {
  return Api.post(`/nff/${nffId}/update`, params);
}

function updateTextEntriesOrderApi({id, ...params}) {
  return Api.post(`/formula/${id}/entries/reorder`, params)
}

function* deleteNff(action) {
  try {
    const {params} = action;

    yield call(deleteNffApi, params);

    yield put({type: REMOVE_USER_NFF, nffId: params});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteNffs(action) {
  try {
    const {params} = action;
    const removedNffs = yield call(deleteNffsApi, params);

    yield put({type: REMOVE_USER_NFFS, params: {removedNffs, coachSprintId: params.coachSprintId}});
  } catch (error) {
    yield onError(error);
  }
}

function* updateNffs(action) {
  try {
    const {params} = action;
    const updatedNffs = yield call(updateNffsApi, params);

    yield put({type: REPLACE_USER_NFFS, params: {updatedNffs, coachSprintId: params.coachSprintId}});
  } catch (error) {
    yield onError(error);
  }
}

function* updateFormula(action) {
  try {
    const {params} = action;
    const updatedFormula = yield call(updateFromulaApi, params);

    yield put({type: REPLACE_USER_FROMULA, params: {updatedFormula, coachSprintId: params.coachSprintId}});
  } catch (error) {
    yield onError(error);
  }
}

function* createNffs(action) {
  try {
    const {params} = action;
    const nffs = yield call(createNffsApi, params);

    yield put({type: ADD_USER_NFFS, params: {nffs, coachSprintId: params.coachSprintId}});
  } catch (error) {
    yield onError(error);
  }
}

function* getNffs() {
  try {
    const nffs = yield call(getNffsApi);

    yield put({type: SET_USER_NFFS, nffs});
  } catch (error) {
    yield onError(error);
  }
}

function* getUserNff(action) {
  try {
    const {params: {id}} = action;
    const currentNff = yield call(getUserNffApi, id);

    yield put({type: SET_USER_NFF, currentNff});
  } catch (error) {
    yield onError(error);
  }
}

function* addFormula(action) {
  try {
    const {params: {id}} = action;
    const currentNff = yield call(addFormulaApi, id);

    yield put({type: SET_USER_NFF, currentNff});
  } catch (error) {
    yield onError(error);
  }
}

function* saveUserNffTitle(action) {
  try {
    const {params} = action;
    const currentNff = yield call(setNffTitleApi, params);

    yield put({type: SET_USER_NFF, currentNff});
  } catch (error) {
    yield onError(error);
  }
}

function* onError(error) {
  notify({
    error,
    context: { component: 'Workout Sagas', severity: 'error' },
  });

  yield put({
    type: ON_MESSAGE,
    message: error,
    messageType: 'error',
  });
}

function* createFormulaEntry(action) {
  try {
    const {params} = action;

    const currentNff = yield call(createFormulaEntryApi, params);

    yield put({type: SET_USER_NFF, currentNff});
  } catch (error) {
    yield onError(error);
  }
}

function* updateFormulaEntry(action) {
  try {
    const {params} = action;
    const currentNff = yield call(updateFormulaEntryApi, params);
    
    yield put({type: SET_USER_NFF, currentNff});
  } catch (error) {
    yield onError(error);
  }
}

function* updateTopic(action) {
  try {
    const {params} = action;
    const currentNff = yield call(updateTopicApi, params);
    
    yield put({type: SET_USER_NFF, currentNff});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteFormulaEntry(action) {
  try {
    const {params} = action;
    const currentNff = yield call(deleteFormulaEntryApi, params);

    yield put({type: SET_USER_NFF, currentNff});
  } catch (error) {
    yield onError(error);
  }
}

function* updateFormulaEntriesOrder(action) {
  try {
    const {params} = action; 
    const currentNff = yield call(updateTextEntriesOrderApi, params);

    yield put({type: SET_USER_NFF, currentNff});
  } catch (error) {
    yield onError(error);
  }
}

function* toggleFormula(action) {
  try {
    const {params} = action; 
    const currentNff = yield call(toggleFormulaApi, params);

    yield put({type: SET_USER_NFF, currentNff});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteFormula(action) {
  try {
    const {params} = action; 
    const currentNff = yield call(deleteFormulaApi, params);

    yield put({type: SET_USER_NFF, currentNff});
  } catch (error) {
    yield onError(error);
  }
}

function* createNff(action) {
  try {
    const {params} = action; 
    const nffs = yield call(createNffApi, params);
    
    yield onSuccess("A new No Fault Formula was successfully created.");
    yield put({type: SET_USER_NFFS, nffs});
  } catch (error) {
    yield onError(error);
  }
}

function* updateNff(action) {
  try {
    const {params} = action; 
    const nffs = yield call(updateNffApi, params);
    
    yield onSuccess("No Fault Formula was successfully updated.");
    yield put({type: SET_USER_NFFS, nffs});
  } catch (error) {
    yield onError(error);
  }
}

function* resetNff() {
  try {
    yield put({type: SET_USER_NFF, currentNff: null});
  } catch (error) {
    yield onError(error);
  }
}

export function* deleteUserNffWatcher() {
  yield takeLatest(DELETE_USER_NFF, deleteNff);
}

export function* deleteUserNffsWatcher() {
  yield takeLatest(DELETE_USER_NFFS, deleteNffs);
}

export function* updateUserNffsWatcher() {
  yield takeLatest(UPDATE_USER_NFFS, updateNffs);
}

export function* createUserNffsWatcher() {
  yield takeLatest(CREATE_USER_NFFS, createNffs);
}

export function* getUserNffsWatcher() {
  yield takeLatest(GET_USER_NFFS, getNffs);
}

export function* saveUserNffTitleWatcher() {
  yield takeLatest(SAVE_USER_NFF_TITLE, saveUserNffTitle);
}
export function* getUserNffWatcher() {
  yield takeLatest(GET_USER_NFF, getUserNff);
}
export function* addNewFormulaWatcher() {
  yield takeLatest(ADD_NEW_FORMULA, addFormula);
}
export function* updateTopicWatcher() {
  yield takeLatest(UPDATE_NFF_TOPIC, updateTopic);
}

export function* updateFormulaWatcher() {
  yield takeLatest(UPDATE_USER_FROMULA, updateFormula);
}

export function* createFormulaEntryWatcher() {
  yield takeLatest(CREATE_FORMULA_ENTRY, createFormulaEntry);
}

export function* updateFormulaEntryWatcher() {
  yield takeLatest(UPDATE_FORMULA_ENTRY, updateFormulaEntry);
}

export function* deleteFormulaEntryWatcher() {
  yield takeLatest(DELETE_FORMULA_ENTRY, deleteFormulaEntry);
}

export function* updateFormulaEntriesOrderWatcher() {
  yield takeLatest(UPDATE_FORMULA_ENTRIES_ORDER, updateFormulaEntriesOrder);
}

export function* toggleFormulaWatcher() {
  yield takeLatest(TOGGLE_FORMULA, toggleFormula);
}

export function* deleteFormulaWatcher() {
  yield takeLatest(DELETE_FORMULA, deleteFormula);
}

export function* createNffWatcher() {
  yield takeLatest(CREATE_USER_NFF, createNff);
}

export function* updateNffWatcher() {
  yield takeLatest(UPDATE_USER_NFF, updateNff);
}

export function* resetNffWatcher() {
  yield takeLatest(RESET_USER_NFFS, resetNff);
}
